import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import openSocket from "../../services/socket-io";
import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import alertSound2 from "../../assets/sound2.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import {version} from '../../../package.json';
//import sendLog from "../../hooks/sendLog";

import { toast } from "react-toastify";
import logFunc from "../../hooks/logFunc";

const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 350,
		...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 350,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			maxWidth: 270,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
}));

//const handleSendMessage = async () => {
//const NotificationsPopOver = () => {
const NotificationsPopOver = () => {
	logFunc(1,"NotificationsPopOver inicia");
	const classes = useStyles();

	const history = useHistory();
	const { user } = useContext(AuthContext);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	
	//const [, setDesktopNotifications] = useState([]);
	//cambio sergio estrada DESATEL
	//toast.success("NotificationsPopOver");
	//const userQueueIds = user.queues.map(q => q.id);
	//let mySerialMap2 = JSON.stringify(Array.from(userQueueIds))
	//sendLog("mySerialMap2 " + mySerialMap2);
	//var tmp1 = typeof userQueueIds;
	//sendLog(tmp1);

	// let mySerialMap3 = JSON.stringify(userQueueIds)
	// sendLog("mySerialMap3 " + mySerialMap3);
	
	//const { tickets } = useTickets({ withUnreadMessages: "true",queueIds: JSON.stringify(userQueueIds), });

	//
	const { tickets } = useTickets({ withUnreadMessages: "true" });
	const [play] = useSound(alertSound);
	const [play2] = useSound(alertSound2);
	const soundAlertRef = useRef();
	const soundAlertRef2 = useRef();

	const historyRef = useRef(history);
	//const userQueueIdsAM2 = user.queues.map(q => q.id);
	

 //let mySerialMap3 = JSON.stringify(tickets);
 //sendLog("NotificationsPopOver Tickets leidos " + mySerialMap3);


	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);
	
	useEffect(() => {
		soundAlertRef2.current = play2;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play2]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	useEffect(() => {
		const userQueueIdsAM = user.queues.map(q => q.id);
		const socket = openSocket();

		socket.on("connect", () => socket.emit("joinNotification"));
		
		socket.on("appMessage", data => {
			logFunc(1,"user?.id: ", user?.id);
			logFunc(1,"NotificationsPopOver useEffect data 2", data)
			/* toast.success("socket.on.appMessage " + data.action + " valor de data  " + JSON.stringify(data), {
			position: "top-center",
			autoClose: 50000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			}); */
			//sendLog("socket.on.appMessage " + data.action + " ticket= " + JSON.stringify(data));
			
			//var queuesUser = Array.from(userQueueIdsAM);
			/* if (
				data.action === "create" && 
				!data.message.read &&
				(data.ticket.userId === user?.id || !data.ticket.userId) */
			if (
				data.action === "create" &&
				!data.message.read &&
				(data.ticket.userId === user?.id || !data.ticket.userId) && userQueueIdsAM.includes(data.ticket.queueId)
				&& !data.ticket.closedTime
			) {
				logFunc(1,"NotificationsPopOver 1");
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
					if (ticketIndex !== -1) {
						logFunc(1,"NotificationsPopOver 2");
						prevState[ticketIndex] = data.ticket;
						return [...prevState];
					}
					logFunc(1,"NotificationsPopOver 3");
					return [data.ticket, ...prevState];
				});
				/* toast.success("tipoNotificacion = " + tipoNotificacion, {
							position: "top-center",
							autoClose: 50000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							}); */
				/* if (tipoNotificacion === "Nuevo" || tipoNotificacion === "Transferido" || tipoNotificacion === "Retornado"){
					return;
				}
				else { */
					//imprimir en el log ticketIdRef.current, sera el que tiene abierto en la pantalla?
					//console.log(" ------ ticketIdRef : " + ticketIdRef);
					const shouldNotNotificate =
						(data.message.ticketId === ticketIdRef.current &&
							document.visibilityState === "visible") ||
						(data.ticket.userId && data.ticket.userId !== user?.id) || data.ticket.isGroup;
					/* toast.success("shouldNotNotificate = " + shouldNotNotificate, {
							position: "top-center",
							autoClose: 50000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							}); */
						
					if (shouldNotNotificate) {
						logFunc(1,"NotificationsPopOver 4 shouldNotNotificate true");
						return;
					}
					logFunc(1,"NotificationsPopOver shouldNotNotificate false 401");
					handleNotifications(data);
				//}
			} else {
				logFunc(1,"NotificationsPopOver 5");
			}
		});
		
		socket.on("ticket", data => {
			logFunc(1,"NotificationsPopOver ticket 1 y data es: ", data);
			//sendLog("socket.on.ticket " + data.action + " ticket= " + JSON.stringify(data));
 			if (data.action === "update" && data.ticket.status === "pending" && !data.ticket.userId && userQueueIdsAM.includes(data.ticket.queueId)) {
				//sendLog("update for alaram ticket " + data.action + " ticket= " + JSON.stringify(data));
				// toast.success("update for alaram ticket  " + JSON.stringify(data), {
					// position: "top-center",
					// autoClose: 50000,
					// hideProgressBar: false,
					// closeOnClick: true,
					// pauseOnHover: true,
					// draggable: true,
					// progress: undefined,
					// }); 
				// tipoNotificacion = "Nuevo";
				if(data.ticket.nuevo === 0){
					logFunc(1,"NotificationsPopOver nuevo");
					handleNotifications2(data);
				} else {
					logFunc(1,"NotificationsPopOver no nuevo");
					handleNotifications4(data);
				}
			} 
			//este es cuando se asigna directamente el operador sin pasar por open
			if (data.action === "update" && data.ticket.status === "open" && data.ticket.userId && userQueueIdsAM.includes(data.ticket.queueId) && data.ticket.nuevo === 0) {
				//sendLog("update for alaram ticket " + data.action + " ticket= " + JSON.stringify(data));
				// toast.success("update for alaram ticket  " + JSON.stringify(data), {
					// position: "top-center",
					// autoClose: 50000,
					// hideProgressBar: false,
					// closeOnClick: true,
					// pauseOnHover: true,
					// draggable: true,
					// progress: undefined,
					// }); 
				// tipoNotificacion = "Nuevo";
					logFunc(1,"NotificationsPopOver nuevo para el asigna operador directo");
					handleNotifications2(data);
			} 
			if (data.action === "control"){
				if(data.accion){
					if(data.accion === "play" && userQueueIdsAM.includes(data.actionqueueId)){
						//console.log('PLAY');
						soundAlertRef2.current();
					}
					if(data.accion === "ping"){
						console.log('Ping');
						const userData = { ping: new Date(), version: version };
						//const { data } = api.put(`/users/${user?.id}`, userData); //TDESATEL para cambiar si ping no responde OK
						api.put(`/users/${user?.id}`, userData);
						/* //console.log('data',data);
						if (data.id){
							//console.log('rptaPing ok');
						} else {
							//console.log('rptaPing mala');
						} */
					}
				}
			}
			if (data.action === "updateUnread"){
				if(data.accion){
					//sendLog("data.accion: " + data.accion + " data.actionqueueId " + data.actionqueueId + " data.actionuserId: " + data.actionuserId + " Usuario: " + user.id + " colas: " + JSON.stringify(Array.from(userQueueIdsAM)) );
					if(data.accion === "Transferido" && data.actionuserId === user?.id){
						handleNotifications3(data);
					}
					if(data.accion === "Retornado" && userQueueIdsAM.includes(data.actionqueueId)){
						handleNotifications4(data);
					}
					if(data.accion === "Reabierto" && data.actionuserId === user?.id){
						handleNotifications5(data);
					}
					if(data.accion === "Nuevo" && data.actionuserId === user?.id){
						handleNotifications6(data);
					}
					if(data.accion === "incomingCall"){
						//sendLog("incomingCall para data.actionuserId: " + data.actionuserId  );
						toast.success("llamando " + data.actionuserId, {
							position: "top-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							});
					}
					if(data.accion === "failedgupshup"){
						//sendLog("incomingCall para data.actionuserId: " + data.actionuserId  );
						toast.error(data.actionuserId, {
							position: "top-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							});
					}
				}
			}
			if (data.action === "updateUnread" || data.action === "delete") {
				logFunc(1,"NotificationsPopOver updateUnread 1");
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				/* setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				}); */
			}
			
			
			// fin cambio sergio estrada data.ticket.queueId
		});



		return () => {
			socket.disconnect();
		};
	}, [user]);

const handleNotifications = data => {
	logFunc(1,"handleNotifications");
		//toast.success("handleNotifications");
		const { message, contact, ticket } = data;

		const options = {
			body: `${message.body} - ${format(new Date(), "HH:mm")}`,
			icon: contact.profilePicUrl,
			//tag: "",
			tag: ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */

		soundAlertRef.current();
	};
	
const handleNotifications2 = data => {
	logFunc(1,"handleNotifications2");
		const options = {
			body: `Nuevo Chat: ${data.ticket.lastMessage} - ${format(new Date(), "HH:mm")}`,
			icon: data.ticket.contact.profilePicUrl,
			//tag: "",
			tag: data.ticket.id,
			renotify: true, 
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${data.ticket.contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */
		soundAlertRef.current();
	};

const handleNotifications3 = data => {
	logFunc(1,"handleNotifications3");
		const options = {
			body: `Chat Transferido: ${data.ticket.lastMessage} - ${format(new Date(), "HH:mm")}`,
			icon: data.ticket.contact.profilePicUrl,
			//tag: "",
			tag: data.ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${data.ticket.contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */
		soundAlertRef.current();
	};

const handleNotifications4 = data => {	
	logFunc(1,"handleNotifications4");
		const options = {
			body: `Chat Retornado: ${data.ticket.lastMessage} - ${format(new Date(), "HH:mm")}`,
			icon: data.ticket.contact.profilePicUrl,
			//tag: "",
			tag: data.ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${data.ticket.contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */
		soundAlertRef.current();
	};

const handleNotifications5 = data => {
	logFunc(1,"handleNotifications5");
	//sendLog(" data de reabierto: ",JSON.stringify(data))
		const options = {
			body: `Chat Reabierto: ${data.ticket.lastMessage} - ${format(new Date(), "HH:mm")}`,
			icon: data.ticket.contact.profilePicUrl,
			//tag: "",
			tag: data.ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${data.ticket.contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */
		soundAlertRef.current();
	};
	
const handleNotifications6 = data => {
	logFunc(1,"handleNotifications6");
		const options = {
			body: `Chat Nuevo: ${data.ticket.lastMessage} - ${format(new Date(), "HH:mm")}`,
			icon: data.ticket.contact.profilePicUrl,
			//tag: "",
			tag: data.ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${data.ticket.contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets`);
			//historyRef.current.push(`/tickets/${ticket.id}`);
		}; 

		/* setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		}); */
		soundAlertRef.current();
	};

	const handleClick = () => {
		//toast.success("handleClick");
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		//toast.success("handleNotifications");
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		//toast.success("NotificationTicket");
		return <div onClick={handleClickAway}>{children}</div>;
		//return true;
	};

/* <IconButton
	onClick={handleClick}
	buttonRef={anchorEl}
	aria-label="Open Notifications"
	color="inherit"
>
	<Badge badgeContent={notifications.length} color="secondary">
		<ChatIcon />
	</Badge>
</IconButton> */
//para quitarl las notificaciones
/*
<IconButton
	onClick={handleClick}
	buttonRef={anchorEl}
	aria-label="Open Notifications"
	color="inherit"
	disabled={true}
>
</IconButton>
*/


	return (
		<>
			<IconButton
				onClick={handleClick}
				buttonRef={anchorEl}
				aria-label="Open Notifications"
				color="inherit"
			>
				<Badge badgeContent={notifications.length} color="secondary">
					<ChatIcon />
				</Badge>
			</IconButton>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<List dense className={classes.tabContainer}>
					{notifications.length === 0 ? (
						<ListItem>
							<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
						</ListItem>
					) : (
						notifications.map(ticket => (
							<NotificationTicket key={ticket.id}>
								<TicketListItem ticket={ticket} />
							</NotificationTicket>
						))
					)}
				</List>
			</Popover>
		</>
	);
};

export default NotificationsPopOver;
