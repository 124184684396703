import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import logFunc from "../../hooks/logFunc";

const useTickets = ({
	searchParam,
	pageNumber,
	status,
	date,
	showAll,
	queueIds,
	withUnreadMessages,
	order,
	userIdf,
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [count, setCount] = useState(0);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async () => {
				logFunc(1,"hooks useTickets inicio");
				try {
					const { data } = await api.get("/tickets", {
						params: {
							searchParam,
							pageNumber,
							status,
							date,
							showAll,
							queueIds,
							withUnreadMessages,
							order,
							userIdf,
						},
					});
					logFunc(1,"hooks useTickets fin");
					setTickets(data.tickets);
					setHasMore(data.hasMore);
					setCount(data.count)
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchTickets();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		searchParam,
		pageNumber,
		status,
		date,
		showAll,
		queueIds,
		withUnreadMessages,
		order,
		userIdf,
	]);

	return { tickets, loading, hasMore, count };
};

export default useTickets;
