import React, { useState, useContext } from "react";
import clsx from "clsx";
import api from "../services/api";
import {version} from '../../package.json';


import {
	makeStyles,
	Drawer,
	AppBar,
	Toolbar,
	List,
	Typography,
	Divider,
	MenuItem,
	IconButton,
	Menu,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import { useLocalStorage } from "../hooks/useLocalStorage";


const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100vh",
	},

	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		minHeight: "48px",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: {
		minHeight: "48px",
	},
	content: {
		flex: 1,
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
}));

const LoggedInLayout = ({ children }) => {
	const classes = useStyles();
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [tittle, setTittle] = useState();
	const [logoCli, setlogoCli] = useState();
	const [logoEmpr, setlogoEmpr] = useState();
	const { handleLogout, loading } = useContext(AuthContext);
	const [drawerOpen, setDrawerOpen] = useLocalStorage("drawerOpen", true);
	const { user } = useContext(AuthContext);
	const [, setuseMic] = useLocalStorage("useMic", false);
	const [, setuseAttach] = useLocalStorage("useAttach", false);
	const [, setseeOtherChats] = useLocalStorage("seeOtherChats", true);

//manejo del microfono
	


	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuOpen(false);
	};

	const handleOpenUserModal = () => {
		setUserModalOpen(true);
		handleCloseMenu();
	};

	const handleClickLogout = () => {
		handleCloseMenu();
		handleLogout();
	};
	
	const mainFunction = async () => {
		let msgDinamic;
		//const msgDinamic = await getOneMessage(1000);
		const { data } = await api.get("/configTdesatel/1");
		
		if (data){
			msgDinamic = "  " + data.clienteEmpresa + " - " + data.marcaEmpresa + " V " + version + " " + data.subVersion + "  <" + user.name + "> ";
			let myArr =JSON.parse(data.txtAux3);
			//console.log(myArr);
			if (myArr && myArr[0]) {
				if (myArr[0].usemic && myArr[0].usemic === "N") {
					setuseMic(false);
				} else {
					setuseMic(true);
				}
				if (myArr[0].useattach && myArr[0].useattach === "N") {
					setuseAttach(false);
				} else {
					setuseAttach(true);
				}
				if (myArr[0].seeotherchats && myArr[0].seeotherchats === "N") {
					setseeOtherChats(false);
				} else {
					setseeOtherChats(true);
				}
			} else {
				setuseMic(true);
				setuseAttach(true);
				setseeOtherChats(true);
			}
			// if (data.intAux1 >= 1){
				// setuseMic(false);
			// } else {
				// setuseMic(true);
			// }
			// if (data.intAux2 === 1){
				// setuseAttach(false);
			// } else {
				// setuseAttach(true);
			// }
			// if (data.intAux3 === 1){
				// setseeOtherChats(false);
			// } else {
				// setseeOtherChats(true);
			// }
			//console.log("useMic en layout: ", useMic);
		}else{
			msgDinamic = "No esta configurada la aplicación, comuníquese con el proveedor";
		}
		setTittle(msgDinamic);
		setlogoEmpr(data.logoMarca);
		setlogoCli(data.logoCliente);
		return msgDinamic;
	}
	
	
	(async () => {
	  await mainFunction()
	})()

	if (loading) {
		return <BackdropLoading />;
	}

	return (
		<div className={classes.root}>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(
						classes.drawerPaper,
						!drawerOpen && classes.drawerPaperClose
					),
				}}
				open={drawerOpen}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					<MainListItems />
				</List>
				<Divider />
			</Drawer>
			<UserModal
				open={userModalOpen}
				onClose={() => setUserModalOpen(false)}
				userId={user?.id}
			/>
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
				color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
			>
				<Toolbar variant="dense" className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => setDrawerOpen(!drawerOpen)}
						className={clsx(
							classes.menuButton,
							drawerOpen && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						className={classes.title}
					>
					<img src={logoCli} alt=""></img>
					{tittle}
					<img src={logoEmpr} alt=""></img>
					</Typography>
					{user.id && <NotificationsPopOver />}
					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={menuOpen}
							onClose={handleCloseMenu}
						>
							<MenuItem onClick={handleOpenUserModal}>
								{i18n.t("mainDrawer.appBar.user.profile")}
							</MenuItem>
							<MenuItem onClick={handleClickLogout}>
								{i18n.t("mainDrawer.appBar.user.logout")}
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />

				{children ? children : null}
			</main>
		</div>
	);
};

export default LoggedInLayout;
