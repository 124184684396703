const rules = {
	user: {
		static: [],
	},

	admin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			"DashboardAll:showall",
		],
	},
	sadmin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			"user:add",
			"Conexiones:add",
			"DashboardAll:showall",
			"users-page:deleteUser",
			"queues-page:deleteQueue",
			"queues-page:addQueue",
			"connections-page:deleteConnection",
		],
	},
};

export default rules;

