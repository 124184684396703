/*eslint eqeqeq:0*/
/*eslint no-mixed-operators:0*/
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
//import sendLog from "../../hooks/sendLog";
import getOneMessage from "../../hooks/getOneMessage";
//import toastError from "../../errors/toastError";

import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

function getRandomInteger(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function uuidv4() {
  var num = getRandomInteger(1, 999999999)
   var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
	dt = new Date().getTime();
    var uuid2 = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid + uuid2 + num.toString();
}

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	//toast.success("TicketActionButtons");
	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		const { data } = await api.get("/configTdesatel/1");
		var sendMsg = 0;
		//console.log("TicketActionButtons configuración es: ",data);
		let myArr = [];
		try {
			myArr =JSON.parse(data.txtAux3);
		} catch (err) {
			myArr = null;
		}
		let yaTipico = 1;
		//console.log("TicketActionButtons myArr es: ",myArr[0]);
		//console.log("TicketActionButtons myArr.tipifica es: ",myArr[0].tipifica);
		if (myArr && myArr[0] && myArr[0].tipifica && myArr[0].tipifica === 'S') { //if (myArr && myArr[0] && myArr[0].tipifica && myArr[0].tipifica === 1) {
				//console.log("requiere tipificacion");
			
			//toast.success("TicketActionButtons handleUpdateTicketStatus" + status + " es grupo? : " + ticket.isGroup);
			
			//const { data2 } = await api.get("/Mensaje/getone/1000");
			//const { data3 } = await api.get("/log/_le_mensaje_dinamico");
			//const { data4 } = await api.get(`/log/${data2}`);
			//var a = data3;
			//a = data4;
			//console.log("que_cosa");
			//await sendLog('**********************'); //funciona para enviar log
			
			let consultatipificacion = await api.put(`/tickettipificado/${ticket.id}`, {userid: userId || null,});
			//console.log("consultatipificacion: ", consultatipificacion);
			//console.log("consultatipificacion.data.message: ", consultatipificacion.data.message);
			
				if (consultatipificacion && consultatipificacion.data && consultatipificacion.data.message === false){
					//console.log("SI requiere tipificacion, pero, no ha tipificado");
					yaTipico = 0;
				}
		} else {
			//console.log("NO requiere tipificacion");
		}
		
		try {
			//console.log("ticket: ",JSON.stringify(ticket));
		if (status === "resolver") {
			//yaTipico = 0; //0 es no y 1 es si
			
			if (yaTipico === 0) {
				//console.log("TicketActionButtons ticket.userId: ",ticket.userId);
				//console.log("TicketActionButtons ticket.id: ",ticket.id);
				toast.error(i18n.t("tickets.noTipificado"));
				setLoading(false);
			}
			else {
				//await sendLog(ticket.queueId);
				//await api.get(`/log/ticket.closedTime`);
				//await api.get(`/log/${ticket.closedTime}`);
				//await api.get(`/log/---- para resolver`);
				const data4  = await api.get(`/MensajesC/listTicketsxContactIDAuth/${ticket.contactId}`);
				if (data4.data === 1) {
					const data6  = await api.get(`/MensajesC/showTicketsxContactIDAuth/${ticket.contactId}`);
					data6.data.forEach(async t => {
						let idUuid2 = uuidv4();
						//console.log("solo tiene 1 conversacion");
						let message3 = {
							read: 1,
							idUuid: idUuid2,
							fromMe: true,
							mediaUrl: "",
							body: `-> El usuario ${ticket.user.name} cerro el chat`
							};
							try {
								await api.post(`/messagescomentuid/${t.id}`, message3);
							} catch (err) {
								toastError(err);
							}
					});
				}
				if (ticket.closedTime){ //si tiene fecha de close fue que se reabrio la conversacion
					status = 'closed';
				}
				else{
					//aca se envia el mensaje
					//verificar si no tiene varios tickes abiertos el mismo contact number
					//await api.get(`/log/----   verificando cantidad de conversaciones abiertas ---- ${ticket.contactId}`); //funciona para enviar log
					//await api.get(`/log/----   fin de verificando cantidad de conversaciones abiertas ----`);
					//const data4  = await api.get(`/MensajesC/listTicketsxContactIDAuth/${ticket.contactId}`);
					//await api.get(`/log/----   fin de verificando cantidad de conversaciones abiertas ---- ${data4.data}`);
					let idUuid = uuidv4();
					if (data4.data > 1) {
						// enviar mensaje a cada ticket en conferencia abierto
						const data5  = await api.get(`/MensajesC/showTicketsxContactIDAuth/${ticket.contactId}`);
						data5.data.forEach(async t => {
							let message2 = {
							read: 1,
							idUuid: idUuid,
							fromMe: true,
							mediaUrl: "",
							body: `-> El usuario ${ticket.user.name} salió del chat`
							};
							try {
								await api.post(`/messagescomentuid/${t.id}`, message2);
							} catch (err) {
								toastError(err);
							}
							
						});
					}
					// find enviar mensaje a cada ticket en conferencia abierto
					//console.log(ticket.broadcastid);
					if (ticket.queueId){ //si es nulo es un chat de salida
						if (data.botCalidad === 0 || ticket.isGroup || data4.data > 1 || ticket.broadcastid){
								status = 'closed';
								sendMsg = 0;
							//fin verificar tickets abieros
						} else {
							status = 'calidad';
							sendMsg = 1;
						}
							
					}else{
						status = 'closed';
					}	
				}
			}			
		}
		if (status === "pending" && !ticket.queueId) { //no existe pending 22 , nunca pasara por aca, se tiene por unas pruebas			
				toast.success("Este chat no puede ser retornado a la cola, no tiene cola asignada, puede transferirlo o resolverlo.", {
				position: "top-center",
				autoClose: 100000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
				setLoading(false);
		} else {
			//aca es el resolver?
			//console.log("Este es el resolver tampco lo debe ejecutar yaTipico: ", yaTipico);
			//console.log("yaTipico: ", yaTipico);
			//console.log("status: ", status);
			if (yaTipico === 1 || status !== "resolver") {
				//console.log("Este es el open que en realidad es reopen status: ", status);
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null,
					reopen: 1,
				});
				setLoading(false);
				if (status === "open") {
					history.push(`/tickets/${ticket.id}`);
				} else {
					history.push("/tickets");
				}
			}
		}
			if (sendMsg === 1){
				var msgDinamic = await getOneMessage(1000);
						const message = {
							read: 1,
							fromMe: true,
							mediaUrl: "",
							body: `\u200e\u200e${msgDinamic.mensaje}`
						};
						await api.post(`/messages/${ticket.id}`, message);
			}
			
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};
	
	
	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						//onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
						onClick={e => handleUpdateTicketStatus(e, "resolver", user?.id)} //pasa el estado a resolver, arriba define si lo pasa a bot o closed dependiendo de la configuracion
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
