//import React, {useState, useEffect, useReducer } from "react"; //useState, useEffect, useContext, useRef
import {useState, useEffect, useReducer } from "react"; //useState, useEffect, useContext, useRef
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import logFunc from "../../hooks/logFunc";
import api from "../../services/api";

const reducer = (state, action) => {
	logFunc(1,"hooks useWhatsApps reducer");
	if (action.type === "LOAD_WHATSAPPS") {
		const whatsApps = action.payload;
		//console.log("####################### reducer useWhatsApps:",whatsApps);
		
		return [...whatsApps];
	}

	if (action.type === "UPDATE_WHATSAPPS") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);
		
		/* toast.success("action.type: " + action.type + "UPDATE_WHATSAPPS:" + JSON.stringify(whatsApp) , {
					position: "top-center",
					autoClose: 50000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
		 */
		if (whatsAppIndex !== -1) {
			state[whatsAppIndex] = whatsApp;
			return [...state];
		} else {
			return [whatsApp, ...state];
		}
	}
	
	if (action.type === "UPDATE_SESSION") {
		
		const whatsApp = action.payload;
		logFunc(1,"hooks useWhatsApps UPDATE_SESSION",whatsApp);
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);
		
		
		
		if (whatsAppIndex !== -1) {
			logFunc(1,"hooks useWhatsApps whatsAppIndex !== -1",whatsApp);
			state[whatsAppIndex].status = whatsApp.status;
			state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
			state[whatsAppIndex].qrcode = whatsApp.qrcode;
			state[whatsAppIndex].retries = whatsApp.retries;
			state[whatsAppIndex].wanNumber = whatsApp.waNumber;
			state[whatsAppIndex].paringCode = whatsApp.paringCode;
			//CONNECTED PAIRING no salio OPENING 
			 /* toast.success("st: " + whatsApp.status + " UPDATE_SESSION:" + JSON.stringify(whatsApp) , {
					position: "top-center",
					autoClose: 50000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});  */
			if (whatsApp.status === "DISCONNECTED") {
				logFunc(1,"hooks useWhatsApps whatsApp.status === DISCONNECTED");
				//aca
				toast.success("whatsApp Desconectado" , {
					position: "top-center",
					autoClose: 200000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				//toastError(whatsApp.status);
			}
			/* if (whatsApp.status === "TIMEOUT") {
				var msgs2 = [];
				msgs2.push(<div>{"TIMEOUT en sesión WhatsApp."}<br />  </div>);
				msgs2.push(<div>{"Verificar conexión a internet"}<br />  </div>);
				msgs2.push(<div>{"Celular desconectado de Internet"}<br />  </div>);
				//aca toast.success(<div>{msgs2}<br /></div>, {
				toast.success(<div>{msgs2}<br /></div>, {
					position: "top-center",
					autoClose: 200000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				//toastError(whatsApp.status);
			} */
			return [...state];
		} else {
			return [...state];
		}
	}

	if (action.type === "DELETE_WHATSAPPS") {
		const whatsAppId = action.payload;

		const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useWhatsApps = () => {
	
	const [whatsApps, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);
	logFunc(1,"hooks useWhatsApps useWhatsApps",whatsApps);
	useEffect(() => {
		setLoading(true);
		const fetchSession = async () => {
			try {
				//const { data } = await api.get("/whatsappmessenger/");
				//aca se cambia si quiero leerlos todos o solo los messenger
				const { data } = await api.get("/whatsappgetall/");
				//const { data } = await api.get("/whatsappgetgupshup/"); /cambiarlo por gupshup
				//console.log("####################### data useWhatsApps: ",data);
				dispatch({ type: "LOAD_WHATSAPPS", payload: data });
				setLoading(false);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("whatsapp", data => {
			if (data.action === "update") {
				logFunc(1,"hooks useWhatsApps socket.on whatsapp 1");
				/* toast.success("update :" + JSON.stringify(data) , {
					position: "top-center",
					autoClose: 50000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}); */
				dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
			}
		});

		socket.on("whatsapp", data => {
			logFunc(1,"hooks useWhatsApps socket.on whatsapp 2");
			if (data.action === "delete") {
				dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
			}
		});

		socket.on("whatsappSession", data => {
			logFunc(1,"hooks useWhatsApps socket.on whatsappSession",data);
			if (data.action === "update") {
				dispatch({ type: "UPDATE_SESSION", payload: data.session });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	return { whatsApps, loading };
};

export default useWhatsApps;
