import axios from "axios";
import { getBackendUrl } from "../config";

// var bDir = window.location.host.split('.');
// var newUrl = window.location.protocol + "//" + bDir[0] + "back";
// for (var i = 1; i < bDir.length; i++) { 
  // newUrl += "." + bDir[i];
// }

//baseURL: newUrl,


//baseURL: process.env.REACT_APP_BACKEND_URL,
const api = axios.create({
	baseURL: getBackendUrl(),
	withCredentials: true,
});

export default api;
